import axios, { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/react-query';

export type ISignupRequest = {
  code: string;
  fingerPrint?: string;
  name: string;
  phone: string;
};

// export const signupSchema: ZodSchema<ISignupRequest> = z.object({
//   phone: zstring).min(1, { message: t('forms.required') }),
// });

export function useSignup() {
  return useMutation<AxiosResponse, AxiosError, ISignupRequest>({
    mutationFn: (payload) => axios.post('/user-service/auth/signup/customer', payload),
  });
}
